// @ts-nocheck
import './SearchUsers.css';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Image,
  Input,
  Modal,
  notification,
  PageHeader,
  Row,
  Skeleton,
  Spin,
  Table,
} from 'antd';
import { Content } from 'antd/es/layout/layout';
import debounce from 'lodash/debounce';
import React from 'react';
import { useDispatch } from 'react-redux';

import { fallBackImage } from '../../../assets/images/fallbackImage';
import { ChangeUserPassword, ResetUserPassword } from '../../../services/api';
import { searchUsers } from '../userEntitySlice';

export const SearchUsers: React.FC<any> = () => {
  const dispatch = useDispatch();
  const isLoading = false; // useSelector(getUserEntityLoading) ?? false

  const [results, setResults] = React.useState([]);
  const [response, setResponse] = React.useState({});
  const fetchRef = React.useRef(0);
  const newPasswordRef = React.useRef('');
  const newPasswordConfirmRef = React.useRef('');
  const skip = 0;
  const take = 20;
  const debounceTimeout = 300;

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = async (value: string) => {
      if ((value.target.value?.length ?? 0) === 0) return;

      fetchRef.current += 1;
      setResults([]);

      const response = (
        await dispatch(
          searchUsers({
            skip: skip ?? 0,
            take: take ?? 20,
            query: value.target.value,
          })
        )
      )?.payload;

      setResults(response?.items);
      setResponse(response);
    };

    return debounce(loadOptions, 500);
  }, [debounceTimeout]);

  const onDismiss = (item) => {
    Modal.confirm({
      title: "Do you want to send an email to reset this user's password?",
      content: (
        <pre>
          <code>
            {item.userName} - {item.email}
          </code>
        </pre>
      ),
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        try {
          ResetUserPassword(item.id)
            .then((result) => {
              notification.success({
                message: 'Success',
                description: 'An email to reset password has been sent',
              });
            })
            .catch((error) => {
              notification.error({
                message: 'Service Error',
                description:
                  error.message ||
                  'Something unexpected happen. Api returned error. See console for details.',
              });
              console.error(error);
            });
        } catch (e) {
          console.error(item);
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const onChangePassword = (item) => {
    Modal.confirm({
      title: `Change ${item.userName}'s Password`,
      content: (
        <pre>
          <code>
            <input
              type="text"
              style={{ marginTop: 20, width: 130 }}
              placeholder="New password"
              ref={newPasswordRef}
            />
            <input
              type="text"
              style={{ marginTop: 20, marginLeft: 20, width: 130 }}
              placeholder="Confirm Password"
              ref={newPasswordConfirmRef}
            />
          </code>
        </pre>
      ),
      icon: <ExclamationCircleOutlined />,
      async onOk() {
        const newPassword = newPasswordRef.current.value;
        const newConfirmPassword = newPasswordConfirmRef.current.value;
        if (newPassword === newConfirmPassword) {
          try {
            ChangeUserPassword(item.id, newPassword)
              .then(() => {
                notification.success({
                  message: 'Success',
                  description: "User's password changed",
                });
              })
              .catch((error) => {
                notification.error({
                  message: 'Service Error',
                  description:
                    error.message ||
                    'Something unexpected happen. Api returned error. See console for details.',
                });
                console.error(error);
              });
          } catch (e) {
            console.error(item);
          }
        }
        else{console.log(error).then(()=>{ notification.error({
          message: 'Passwords doesnt match',
          description:
            error.message ||
            'Passwords doesnt match',
        });})}
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const UserDetailTable = [
    {
      title: '',
      dataIndex: 'profilePicture',
      key: 'profilePicture',
      width: 150,
      render: (_: any, record) => {
        return (
          <Image
            height={75}
            width={75}
            style={{ objectFit: 'cover', borderRadius: '50%' }}
            key={`row_image_${record.id}`}
            src={`${record.profilePicture}`}
            fallback={fallBackImage}
            preview={{
              src:
                record.profilePicture?.length > 0 ? record.profilePicture : '',
            }}
          />
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_: string, record: any) => (
        <p title={!record.isActive && 'User is not active'}>
          {record.name} {record.surname}{' '}
          <pre>
            <code style={{ color: '#E01A76' }}>
              {record.isActive ? record.userName : record.userName + ' (P)'}
            </code>
          </pre>
        </p>
      ),
    },

    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Notification Id',
      dataIndex: 'notificationDeviceId',
      key: 'notificationDeviceId',
      render: text => <div style={{ width: '200px' }}>{text}</div>,
    },
    {
      title: 'Creation Time (Locale)',
      dataIndex: 'createdOnUtc',
      key: 'createdOnUtc',
      render: (_: any, record: any) =>
        new Date(record.createdOnUtc).toLocaleString(),
    },

    {
      title: 'Operations',
      dataIndex: 'id',
      width: 200,
      render: (_: any, record) => (
        <div key={`row_operations_${record.id}`}>
          <Button
            key={`reset_password_${record.id}`}
            style={{ marginLeft: 10, marginBottom: 10 }}
            onClick={() => onChangePassword(record)}
            type="default"
            size="small"
          >
            Password Reset
          </Button>
          <Button
            key={`reset_password_email_${record.id}`}
            style={{ marginLeft: 10 }}
            onClick={() => onDismiss(record)}
            type="default"
            size="small"
          >
            Password Reset Email
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        className="site-page-header"
        onBack={() => null}
        title="Search Users"
      />
      <Content style={{ margin: '0px 16px 0', overflow: 'initial' }}>
        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Row style={{ marginBottom: 24 }}>
            <div style={{ width: '100%' }}>
              <Spin spinning={isLoading}>
                <Row style={{ justifyContent: 'space-between' }}>
                  <Col>
                    <Form layout={'inline'}>
                      <Form.Item
                        label="User Name"
                        name="userName"
                        rules={[
                          { required: true, message: 'Please select a user' },
                        ]}
                      >
                        <Input
                          onChange={debounceFetcher}
                          placeholder="Search username, email or name of user"
                        />
                      </Form.Item>
                    </Form>
                  </Col>

                  <Col>
                    {response?.totalCount && (
                      <p>
                        Total {response?.totalCount} record found regarding
                        criteria. Shown max 20.{' '}
                      </p>
                    )}
                  </Col>
                </Row>
              </Spin>
            </div>
          </Row>
        </div>

        <div
          className="site-layout-background"
          style={{ padding: 24, textAlign: 'center' }}
        >
          <Spin spinning={isLoading}>
            <Skeleton loading={isLoading} active>
              {results?.length ? (
                <Card>
                  <Table
                    rowClassName={(record, index) =>
                      record.isActive
                        ? 'table-row-user-active'
                        : 'table-row-user-passive'
                    }
                    size="small"
                    columns={UserDetailTable}
                    dataSource={results}
                    pagination={false}
                  />
                </Card>
              ) : (
                <Card>
                  <Empty />
                </Card>
              )}
            </Skeleton>
          </Spin>
        </div>
      </Content>
    </div>
  );
};
